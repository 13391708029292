import React, { useRef } from "react";
import { Form } from "@unform/web";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { node, string, func } from "prop-types";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import SectionTitle from "../../../../components/SectionTitle";
import SocialAuthentication from "../../../../components/SocialAuthentication";
import useAxios from "../../../../hooks/useAxios";
import { useRedirect } from "../../../../hooks/useRedirect";
import { useUserContext } from "../../../../contexts/User";
import validationDictionary from "../../../../constants/validationDictionary";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";

import AuthenticationParagraph from "../../components/AuthenticationParagraph";
import FieldsGrid from "../../components/FieldsGrid";

import styles from "./styles.module.scss";
import { parseEmail } from "../../../../helpers";

const SignInForm = ({ title, description, onSuccess }) => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { getRedirect, clearRedirect } = useRedirect();
  const { updateInkoins, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();
  const { updateUserStatus, checkUserStatusAndRedirect } =
    isUserFullySignedUp();

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_LOGIN)
          .required(validationDictionary.INVALID_LOGIN),
        password: Yup.string()
          .min(8, validationDictionary.INVALID_LOGIN)
          .required(validationDictionary.INVALID_LOGIN),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/login",
        data: { ...data, email: parseEmail(data.email) },
      });

      console.log('2');

      if (response.error && response.status === 401) {
        showSnackBar({
          timeout: 5000,
          text: response.data.message,
          error: true
        });
        return;
      }

      if (!response.token) {
        formRef.current.setErrors({
          email: response.data.message,
          password: response.data.message,
        });
        return;
      }

      // if (response.token && !response.user.accepted_terms) {
      //   setUserData(response.user);

      //   if (response.user.type === 'PF' && response.user.wallet) {
      //     updateInkoins(response.user.wallet.coins);
      //     localStorage.setItem(
      //       "@user_inkoins",
      //       JSON.stringify(response.user.wallet.coins)
      //     );
      //   }

      //   // history.push("/autenticacao/aceite-os-termos-de-uso");
      //   return;
      // }

      if (response.token && !response.user.email_verified_at) {
        setUserData(response.user);

        if (response.user.wallet) {
          updateInkoins(response.user.wallet.coins);
          localStorage.setItem(
            "@user_inkoins",
            JSON.stringify(response.user.wallet.coins)
          );
        }
      }

      if (response.user.type === "PJ" && !response.user.email_verified_at) {
        localStorage.setItem("@user_token", response.token);
        localStorage.setItem("@user_data", JSON.stringify(response.user));

        // history.push("/para-empresas/autenticacao/confirme-seu-email");
        return;
      }

      if (response.user.type === "PF") {
        if (response.user.wallet) {
          updateInkoins(response.user.wallet.coins);
          localStorage.setItem(
            "@user_inkoins",
            JSON.stringify(response.user.wallet.coins)
          );
        }
      }

      localStorage.setItem("@user_token", response.token);
      localStorage.setItem("@user_data", JSON.stringify(response.user));

      checkUserStatusAndRedirect()

      /* REDIRECT USER TO FINISH SIGNUP IF NOT COMPLETE -> */
      // updateUserStatus({ status: response.user.user_signup_status });

      // if (response.user.user_signup_status) {
      //   checkUserStatusAndRedirect();
      //   return;
      // }
      /* <- REDIRECT USER TO FINISH SIGNUP IF NOT COMPLETE */

      if (formRef.current) formRef.current.reset()

      const redirectPath = getRedirect();

      if (redirectPath) {
        clearRedirect();
        history.push(redirectPath);
        onSuccess();
        return;
      }

      // if (!redirectPath) {
      //   showSnackBar({
      //     timeout: 5000,
      //     text: "Bem-vindo(a) de volta!",
      //   });
      // }

      if (response.user.type === "PJ" && !response.user.inkluer) return history.push("/para-empresas");

      history.push("/");
    } catch (error) {
      console.log('INKLUA SignInForm error: ', error);

      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors.email = validationDictionary.INVALID_LOGIN;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        showSnackBar({
          timeout: 5000,
          text: "Houve um problema, tente novamente.",
          error: true
        });
      }
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id="sign-in-form">
      <SectionTitle small centered level="h2" children={title} />
      <AuthenticationParagraph>{description}</AuthenticationParagraph>
      {/* <SocialAuthentication /> */}
      <FieldsGrid>
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
        />
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />
      </FieldsGrid>
      <Link
        className={styles.forgotPasswordLink}
        to="/autenticacao/esqueci-minha-senha"
      >
        Esqueci minha senha
      </Link>
      <FieldsGrid size="small">
        <SquareButton disabled={loading} text="Entrar" type="submit" />
        <SquareLinkButton
          type="outline"
          text="Criar uma conta"
          url="/autenticacao/cadastro/candidato"
          dataTest="signup"
        />
      </FieldsGrid>
    </Form>
  );
};

export default SignInForm;

SignInForm.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
  onSuccess: func,
};

SignInForm.defaultProps = {
  title: string.isRequired,
  description: node.isRequired,
  onSuccess: () => { },
};
